import React, { useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import './feedbackView.scss';
type Props = {
  open: boolean;
  handleClose: any;
  data: any;
};

const FeedbackView = (props: Props) => {
  let { open, handleClose, data } = props;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (
      data &&
      Object.keys(data).length === 0 &&
      Object.getPrototypeOf(data) === Object.prototype
    )
      setLoader(true);
    else setLoader(false);
  }, [data]);

  return (
    <Modal
      title={<div className="title font">Detail Feedback</div>}
      centered
      open={open}
      onCancel={handleClose}
      width={560}
      footer={null}
      className="DialogWrapper"
    >
      {loader ? (
        <Spin spinning={loader} size="default" className="recipeLoader" />
      ) : (
        <div className="feedbackViewContainer">
          <img src={data?.thumbnail} alt="" className="feedbackViewImage" />

          <div className="feedbackViewIssueType">{data?.issue_type}</div>
          <div className="feedbackViewScreen">{data?.screen}</div>
          <div className="feedbackViewDescription">{data?.feedback}</div>
        </div>
      )}
    </Modal>
  );
};

export default FeedbackView;
