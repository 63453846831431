import { Route, Routes } from 'react-router-dom';
import Login from '../pages/auth';
import ForgotPassword from '../pages/forgotPassword';
import ResetPassword from '../pages/resetPassword';
import ConfirmPage from '../pages/confirmationPage';
import ConfirmEmail from '../pages/confirmationForEmail';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Login />} />
      <Route path="/reset-password" element={<ForgotPassword />} />
      <Route path="/forgot-Password" element={<ResetPassword />} />
      <Route path="/confirm-for-verification" element={<ConfirmEmail />} />
      <Route path="/confirm" element={<ConfirmPage />} />
    </Routes>
  );
};
