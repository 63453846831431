import { Row, Col } from 'antd';
import './styles.scss';

type Props = {
  count1: number;
  title1: string;
  count2?: number;
  title2?: string;
  title3?: string;
  title4?: string;
  component?: string;
};

const ListCounts = (props: Props) => {
  return (
    <div className="list-count">
      <Row>
        <Col span={10}>
          <h1>
            {props.count1?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </h1>
          <div className="l2">{props.title1}</div>
        </Col>
        {props.title2 && (
          <Col span={10}>
            <h1>
              {props.count2 &&
                props.count2?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
            </h1>
            <div className="l2">{props.title2}</div>
          </Col>
        )}
      </Row>
      {/* <Row>
        {props?.component === 'Inventory List' && (
          <Col span={10}>
            <h1>
              {props.count1?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </h1>
            <div className="l2">{props.title3}</div>
          </Col>
        )}
        {props?.component === 'Inventory List' && (
          <Col span={10}>
            <h1>
              {props.count1?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </h1>
            <div className="l2">{props.title4}</div>
          </Col>
        )}
      </Row> */}
    </div>
  );
};

export default ListCounts;
