import { Table } from 'antd';
import './style.scss';

type Props = {
  dataSource: any;
  columns: any;
  items: any;
  limit?: any;
};
type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

const BranchList = (props: Props) => {
  const { dataSource, columns, limit } = props;

  return (
    <>
      <Table
        dataSource={[...dataSource]}
        columns={columns}
        pagination={{
          pageSize: limit,
          position: [
            'none' as TablePaginationPosition,
            'none' as TablePaginationPosition,
          ],
        }}
      />
    </>
  );
};

export default BranchList;
