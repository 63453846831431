import React, { useState, useEffect } from 'react';
import { Table } from 'antd';

import './styles.scss';

type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

type Props = { dataSource: any; userColumns: any; limit: any };
const UserList = (props: Props) => {
  let { dataSource, userColumns, limit } = props;
  const [tableData, setTableData] = useState([{}]);

  useEffect(() => setTableData(dataSource), [dataSource]);

  return (
    <>
      <Table
        dataSource={[...tableData]}
        columns={userColumns}
        pagination={{
          pageSize: limit,
          position: [
            'none' as TablePaginationPosition,
            'none' as TablePaginationPosition,
          ],
        }}
      />
    </>
  );
};

export default UserList;
